.body {
  color: #fff !important;
}

.App {
  text-align: center;
}

.MuiButton-root {
  color: white !important;
}

.MuiImageList-root {
  justify-content: center;
}

.MuiImageListItem-root {
  width: 100% !important;
}

.MuiCircularProgress-colorPrimary {
  color: #fff !important;
}

.MuiButton-outlined {
  border: 1px solid rgba(255, 255, 255, 1) !important;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.MuiCircularProgress-colorSecondary {
  color: #fff !important;
}

a {
  text-decoration: none !important;
}
