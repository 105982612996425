@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700;800&display=swap");

html {
  height: 100%;
  background: #000000;
  font-family: "Open Sans", sans-serif !important;
}

h6 {
  color: #ffffff !important;
  font-family: "Open Sans", sans-serif !important;
}

h1 {
  color: #ffffff !important;
  font-family: "Open Sans", sans-serif !important;
}

h3 {
  color: #ffffff !important;
  font-family: "Open Sans", sans-serif !important;
}

p {
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #000000;
}

#root {
  background: #000000;
  background-size: cover;
}

code {
  font-family: "Open Sans", sans-serif !important;
}
